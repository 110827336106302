$fontFamily: "Work Sans", sans-serif;

$fontLight: 300;
$fontMedium: 500;
$fontSemiBold: 600;
$fontBold: 700;

$fontSizeSmallest: 0.5rem;
$fontSizeSmaller: 0.75rem;
$fontSizeSmall: 0.875rem;
$fontSizeDefault: 1rem;
$fontSizeTitle: 1.5rem;
$fontSizeTitleLg: 1.75rem;
$fontSizeLg: 2rem;

$inputBoxShadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
$cardBoxShadow: 0 2px 32px 0 rgba(0, 0, 0, 0.2);
$btnHoverGlow: 0 0 11px 0px rgba(21, 200, 208, 0.85);
$btnHoverGlowWhite: 0 0 11px 0px rgba(255, 255, 255, 0.85);
$inputInvalidGlow: 0 0 11px 0px rgba(218, 75, 93, 0.85);
$menuHoverTextGlow: 0px 0px 8px rgba(21, 200, 208, 0.5);
$menuHoverTextGlow2: 0px 0px 8px rgba(255, 255, 255, 0.85);
$btnBackground: linear-gradient(132deg, #2647ad 0%, #3466ff 47%, #11e7c9 100%);
$btnBackgroundSmall: linear-gradient(132deg, #3261f0 47%, #20aee1 100%);
$btnBackgroundHover: linear-gradient(112deg, #3466ff 20%, #11e7c9 100%);

$disabledBackground: #dddddd;

$lightTextColor: #878c9c;
$normalTextColor: #6c677f;
$blackTextColor: #5d5a6c;
$darkestBlue: #091d58;
$darkBlue: #12308e;
$brightDarkBlue: #3456bc;
$lightDarkBlue: #183d98;
$lightBlue: #18ced4;
$red: #da4b5d;
$pink: #ffcfd5;
$white: #fff;
$facebookColor: #3c63b1;
$googleColor: #d8493d;
$unityColor: #000000;
$faqQuestionColor: #3773e9;
$backgroundFaq: #ffffff;
$backgroundFaqAnswer: #f5f7f9;
$selectItemsBlue: #2968e6;

$default-transition: all 0.3s ease;

$zIndexes: (
  bds-spinner: 150,
);

// Min-width of the most used device breakpoints
$small-devices-width: 600px;
$medium-devices-width: 768px;
$large-devices-width: 992px;
$xlarge-devices-width: 1200px;
