@use "variables";
/* ============================================================
  RESET
============================================================ */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote {
  &:before,
  &:after {
    content: "";
    content: none;
  }
}

q {
  &:before,
  &:after {
    content: "";
    content: none;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* ============================================================
  BOX SIZING & CLEARFIX
============================================================ */

* {
  box-sizing: border-box;
  transition: all 0.2s ease;

  &:before,
  &:after {
    box-sizing: border-box;
  }
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

/* ============================================================
  BOX SIZING & CLEARFIX
============================================================ */

/* ============================================================
  GENERAL
============================================================ */

body {
  margin: 0;
  padding: 0;
  font-family: variables.$fontFamily;
  font-size: 16px;
}

h1 {
  font-size: 4rem;
  line-height: 4.5rem;
  font-weight: variables.$fontBold;
  color: variables.$white;
  margin-bottom: 16px;
  text-align: center;
  width: 100%;

  @media screen and (max-width: 1680px) {
    font-size: 3rem;
    line-height: 3.5rem;
  }

  @media screen and (max-width: 1440px) {
    font-size: 2.75rem;
    line-height: 3.5rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 2.2rem;
    line-height: 3rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  br {
    @media screen and (max-width: 480px) {
      display: none;
    }
  }
}

h2 {
  font-size: 2.5rem;
  line-height: 3rem;
  font-weight: variables.$fontSemiBold;
  color: variables.$darkBlue;
  margin-bottom: 24px;

  @media screen and (max-width: 1440px) {
    font-size: 1.75rem;
    line-height: 2.5rem;
    margin-bottom: 8 px;
  }

  @media screen and (max-width: 1024px) {
    font-size: 1.75rem;
    line-height: 2.5rem;
    margin-bottom: 24px;
  }

  @media screen and (max-width: 768px) {
    text-align: center;
    font-size: 1.75rem;
    line-height: 2.5rem;
    margin-bottom: 16px;
  }

  @media screen and (max-width: 480px) {
    text-align: center;
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 16px;
  }

  br {
    @media screen and (max-width: 580px) {
      display: none;
    }
  }
}

h3 {
  font-size: 2rem;
  line-height: 3rem;
  font-weight: variables.$fontSemiBold;
  color: variables.$darkBlue;
  margin-bottom: 16px;

  @media screen and (max-width: 1440px) {
    font-size: 1.4rem;
    line-height: 2rem;
    margin-bottom: 16px;
  }

  @media screen and (max-width: 1024px) {
    font-size: 1.4rem;
    line-height: 2rem;
    margin-bottom: 16px;
  }
  @media screen and (max-width: 680px) {
    font-size: 1.25rem;
    line-height: 2rem;
    margin-bottom: 16px;
  }
}

h4 {
  font-size: 1.75rem;
  line-height: 2.5rem;
  font-weight: variables.$fontSemiBold;
  color: variables.$darkBlue;
  margin-bottom: 16px;

  @media screen and (max-width: 1440px) {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 16px;
  }

  @media screen and (max-width: 1440px) {
    font-size: 1.25rem;
    line-height: 2rem;
    margin-bottom: 16px;
  }
}

h5 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: variables.$fontSemiBold;
  color: variables.$darkBlue;
  margin-bottom: 16px;

  @media screen and (max-width: 1440px) {
    font-size: 1.25rem;
    line-height: 2rem;
    margin-bottom: 16px;
  }
}

h6 {
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: variables.$fontSemiBold;
  color: variables.$darkBlue;
  margin-bottom: 16px;

  @media screen and (max-width: 768px) {
    text-align: center;
  }

  @media screen and (max-width: 480px) {
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin-bottom: 16px;
    text-align: center;
  }
}

p {
  font-size: 1.3125rem;
  line-height: 2rem;
  color: variables.$normalTextColor;
  font-weight: variables.$fontLight;
  margin-bottom: 8px;

  @media screen and (max-width: 1440px) {
    font-size: 1.125rem;
    line-height: 2rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

p.bds-pIntro {
  font-size: 1.5rem;
  line-height: 2.5rem;
  color: variables.$normalTextColor;
  font-weight: variables.$fontLight;
  margin-bottom: 8px;

  @media screen and (max-width: 1440px) {
    font-size: 1.25rem;
    line-height: 2rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 1.125rem;
    line-height: 2rem;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.bds-textWhite {
  color: variables.$white;
}

.bds-pSmallest {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 8px;
}

.bds-pStrong {
  font-weight: variables.$fontSemiBold;
  color: variables.$darkBlue;
}

.bds-textCentered {
  text-align: center;
}

.bds-mailTo {
  color: variables.$lightBlue;
  text-decoration: underline;
}

.flex-col {
  flex-direction: column !important;
}

.bds-callToActionText {
  font-size: 2.5rem;
  color: variables.$darkBlue;
  line-height: 3.5rem;
  text-align: left;
  font-weight: variables.$fontLight;
  max-width: 1024px;

  &--white {
    color: variables.$white;
    text-align: center;
  }

  &--vertical {
    margin-bottom: 48px;
  }

  @media screen and (max-width: 1680px) {
    font-size: 2rem;
    line-height: 3rem;
  }

  @media screen and (max-width: 1440px) {
    font-size: 1.75rem;
    line-height: 2.5rem;
  }

  @media screen and (max-width: 1280px) {
    font-size: 1.75rem;
    line-height: 3rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
    line-height: 2.5rem;
    text-align: center;
  }

  & br {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}

/* ============================================================
  GENERAL - END
============================================================ */

/* ============================================================
  BDS SECTION
============================================================ */

.bds-section {
  padding: 112px 32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1440px) {
    padding: 96px 32px;
  }

  @media screen and (max-width: 768px) {
    padding: 80px 32px;
  }

  @media screen and (max-width: 480px) {
    padding: 72px 24px;
  }

  &:first-child {
    min-height: calc(100vh - 192px);
    margin-top: 80px;

    @media screen and (max-width: 1024px) {
      min-height: calc(100vh - 232px);
    }

    @media screen and (max-width: 600px) {
      min-height: calc(100vh - 256px);
    }
  }

  &--noPTop {
    padding-top: 0;
  }

  &--b2bInfo {
    background: variables.$backgroundFaqAnswer;
  }

  &--first {
    padding-top: 180px;

    @media screen and (max-width: 1440px) {
      padding-top: 152px;
    }

    @media screen and (max-width: 768px) {
      padding-top: 144px;
    }

    @media screen and (max-width: 480px) {
      padding-top: 120px;
    }
  }

  &--firstLanding {
    padding-top: 0px;

    @media screen and (max-width: 1024px) {
      padding-top: 96px;
    }

    @media screen and (max-width: 480px) {
      padding-top: 72px;
    }
  }

  &--callToAction {
    padding: 56px 32px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
    position: relative;

    .bds-container {
      align-items: center;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      .bds-callToActionText {
        @media screen and (max-width: 768px) {
          margin-bottom: 48px;
        }
      }
    }

    @media screen and (max-width: 1440px) {
      padding: 40px 32px;
    }

    @media screen and (max-width: 768px) {
      padding: 80px 32px;
    }
  }

  &--callToActionVertical {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
    position: relative;

    .bds-container {
      flex-direction: column;
      align-items: center;

      .bds-callToActionText {
        margin-bottom: 48px;
      }
    }
  }

  &--gradientBackground {
    background: variables.$btnBackground;
  }

  &--colouredBackground {
    background: variables.$darkBlue;
  }
}

/* ============================================================
  BDS SECTION - END
============================================================ */

/*============================================================
  BDS CONTAINER
============================================================ */

.bds-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1424px;
  margin-bottom: 64px;

  @media screen and (max-width: 1440px) {
    max-width: 1152px;
  }

  &--vertical {
    flex-direction: column;
    align-items: center;
  }

  &--textBlock {
    max-width: 816px;
  }

  &--cards {
    align-items: initial;
  }

  &--form {
    width: 40%;
    max-width: 680px;
    align-items: center;

    @media screen and (max-width: 1024px) {
      width: 60%;
    }

    @media screen and (max-width: 768px) {
      width: 80%;
    }

    @media screen and (max-width: 600px) {
      width: 100%;
    }

    form {
      width: 100%;
    }
  }

  &--2Col {
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }

  &--2ColInverted {
    @media screen and (max-width: 1024px) {
      flex-direction: column-reverse;
      align-items: center;
      text-align: center;
    }
  }

  &--3Col {
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

/*============================================================
  BDS CONTAINER - END
============================================================ */

/* ============================================================
    BDS FORM ITEM
============================================================ */

.bds-formItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  @media screen and (max-width: 400px) {
    margin-bottom: 24px;
  }

  &--invalid {
    &::before {
      content: "";
      border: solid transparent;
      border-bottom-color: variables.$pink;
      border-width: 10px;
      position: absolute;
      margin-left: 24px;
      z-index: -1;
      margin-top: 48px;

      @media screen and (max-width: 400px) {
        margin-top: 44px;
      }
    }

    &::after {
      content: attr(data-tooltip);
      min-width: 100%;
      font-size: 1rem;
      font-weight: variables.$fontLight;
      line-height: 1.5rem;
      margin: 0;
      background: variables.$pink;
      -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
      color: variables.$red;
      padding: 16px 24px;
      border-radius: 4px;
      top: 20px;
      z-index: -2;
      width: 100%;
      display: inline-block;
      position: relative;

      @media screen and (max-width: 1440px) {
        font-size: 0.875rem;
        font-weight: variables.$fontLight;
        line-height: 1.25rem;
      }
    }

    .bds-input {
      border: 1px solid variables.$red !important;

      &:focus {
        order: 1px solid variables.$red !important;
        box-shadow: variables.$inputInvalidGlow !important;
      }

      &:active {
        order: 1px solid variables.$red !important;
        box-shadow: variables.$inputInvalidGlow !important;
      }
    }
  }
}

.bds-formItem--2row {
  .bds-formItem--invalid {
    &::before {
      margin-top: 82px;
    }
  }
}

/* ============================================================
    BDS FORM ITEM - END
============================================================ */

/* ============================================================
    BDS CARD
============================================================ */

.bds-card {
  background: variables.$white;
  box-shadow: variables.$cardBoxShadow;
  padding: 48px;

  @media screen and (max-width: 1440px) {
    padding: 40px;
  }

  @media screen and (max-width: 480px) {
    padding: 32px;
  }
}

/* ============================================================
    BDS CARD - END
============================================================ */

/* ============================================================
    BDS GRID
============================================================ */

.bds-md4col {
  margin: 0 8px 0 8px;

  @media screen and (min-width: 860px) {
    width: 33%;
  }

  @media screen and (max-width: 859px) {
    width: 100%;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.bds-md6col {
  margin: 0 8px 0 8px;
  width: 50%;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

/* ============================================================
    BDS GRID - END
============================================================ */

/* ============================================================
    BDS COLUMN
============================================================ */

.bds-column {
  display: flex;
  flex-direction: column;

  &--contentCentered {
    align-items: center;
    justify-content: flex-start;
  }

  &--contentLeft {
    align-items: flex-start;
    justify-content: flex-start;
  }

  &--contentRight {
    align-items: flex-end;
    justify-content: flex-start;
  }

  &--contentJustified {
    align-items: space-between;
    justify-content: flex-start;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 859px) {
    margin-bottom: 56px;
  }
}

/* ============================================================
    BDS COLUMN - END
============================================================ */

/* ============================================================
    BDS MARGINS
============================================================ */

.mb0 {
  margin-bottom: 0;
}

.mt-32 {
  margin-top: -32px;
}

.mt64 {
  margin-top: 64px;
}

.mt48 {
  margin-top: 48px;
}

.mt40 {
  margin-top: 40px;
}

/* ============================================================
    BDS MARGINS - END
============================================================ */
